
    <!--  修学心得-->
    <template>
    <div class="experience flex-between-start">
        <div class="exper_left">
            <ul>
				<li style="font-weight: 600;letter-spacing: 1em;">
                    <div class="time oneLine">{{$t('lastDate')}}</div>
                    <div class="title oneLine">{{$t('articleTitle1')}}</div>
                    <div class="author oneLine" style="letter-spacing: 1em;">
						{{$t('articleName')}}
                        <!-- <span style="letter-spacing:0px">{{$t('articleName')}}</span>:{{item.news_author}} -->
                        <!-- <span  style="letter-spacing:0px">{{$t('articleName')}}</span> -->
                    </div>
                </li>
                <li @click="linkToDetail(item)" v-for="(item,index) in contentList" :key="index">
                    <div class="time oneLine">{{item.data}}</div>
                    <div class="title oneLine">{{item.news_title}}</div>
                    <div class="author oneLine">
                        <!-- <span style="letter-spacing:0px">{{$t('articleName')}}</span>:{{item.news_author}} -->
                       {{item.news_author}}
                    </div>
                </li>
            </ul>
            <!-- 分页 -->
            <div class="pageBar">
                <div class="block">
                    <el-pagination
                        :pager-count="5"
                        @current-change="handleCurrentChange"
                        background
                        :page-size="page.per_page"
                        layout=" prev, pager, next"
                        :total="page.total"
                        :prev-text="$t('preText')"
                        :next-text="$t('nextText')"
                        :current-page="page.current_page"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <!-- 右部分子菜单 -->
        <div class="exper_right">
            <ul>
                <li
                    @click="changeNav(index)"
                    class="navItem"
                    v-for="(nav,index) in cateList"
                    :key="index"
                    :class="{'navItemActive':index==nowNav}"
                >{{nav.cate_name}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
// import { getTimeBeforeDay } from "../../common/js/common";
import {
    getCateList,
    StudyExperienceCate,
    StudyExperienceList
} from "../../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            nowNav: 0,
            // childIndex: 0, //菜单的index
            currentNavId: "", //菜单id
            page: {
                total: 0,
                per_page: 18,
                current_page: 1,
                last_page: 1
            },
            cateList: [
                // {
                //     //三级菜单
                //     id: 1,
                //     cate_name: "修學心得"
                // },
                // {
                //     id: 1,
                //     cate_name: "生命的升華與沉澱"
                // },
                // {
                //     id: 1,
                //     cate_name: "雲開的心得"
                // },
                // {
                //     id: 1,
                //     cate_name: "緣生可貴"
                // },
                // {
                //     id: 1,
                //     cate_name: "自覺自在"
                // }
            ],
            contentList: [
                // {
                //     create_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // },
                // {
                //     create_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // },
                // {
                //     create_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // }
            ]
        };
    },
    watch: {},
    methods: {
        changeNav(index) {
            //改变导航（需要）
            this.nowNav = index;
            // localStorage.setItem("nowNav", index);
            this.currentNavId = this.cateList[index].id;
            this.getExperienceList({ limit: this.page.per_page, page: 1 });
            // this.contentList = this.cateList[index].id;
        },
        handleCurrentChange(e) {
            this.page.current_page = e;
            this.getExperienceList({ limit: this.page.per_page, page: e });
        },
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=experience&id='+item.id+'&title='+this.title);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: { name: "experience", id: item.id, title: this.title }
            // }); //跳转到心得详情
        },
        //获取展示数据
        getExperienceList(page) {
            StudyExperienceList(this.currentNavId, page).then(res => {
                this.page.total = res.total;
                this.page.current_page = res.current_page;
                this.page.last_page = res.last_page;
                // this.contentList = getTimeBeforeDay(res.data, "data");
                this.contentList = res.data;
            });
        }
    },
    mounted() {
        // this.getExperienceList({
        //     limit: this.page.per_page,
        //     page: this.page.current_page
        // });
    },
    created() {
        //获取子菜单栏
        getCateList().then(res => {
            let id = res[2][0].id;
            StudyExperienceCate(id).then(res => {
                this.cateList = res;
                this.nowNav =  0;
                this.currentNavId = res[this.nowNav].id;
                this.getExperienceList({
                    limit: this.page.per_page,
                    page: this.page.current_page
                });
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.pageBar {
    margin-top: 50px;
    text-align: center;
}

.experience {
    width: 780px;
    color: #333333;
    font-size: 15px;
    margin: 20px 10px;
    text-align: left;

    .exper_left {
        color: #000000;
        width: 626px;


        ul {
			 /* min-height 167px; */
			 min-height: 644px;
            padding: 2px 8px;
            border: 2px solid #B5C6D8;

            li {
                cursor: pointer;
                width: 100%;
                /* height: 40px;
                line-height: 40px; */
				height: 32px;
                line-height:32px;
                border-bottom: 1px dashed #D4D4D4;
            }

            li:hover {
                color: #1482d9;
            }

            li:last-child {
                border-bottom: none;
            }

            div {
                display: inline-block;
            }

            .time {
                width: 120px;
            }

            .title {
                width: 350px;
            }

            .author {
                width: 120px;
                letter-spacing: 5px;
            }
        }
    }

    .exper_right {
        width: 170px;

        li {
            cursor: pointer;
            width: 153px;
            height: 27px;
            line-height: 27px;
            border: 2px solid #B5C6D8;
            border-left: 0px solid #B5C6D8;
            border-radius: 0px 14px 14px 0px;
            margin-bottom: 5px;
            padding-left: 15px;
        }

        li:hover {
            background-color: #B5C6D8;
            color: #ffffff;
        }

        .navItemActive {
            background-color: #B5C6D8;
            color: #ffffff;
        }
    }
}
</style>
